// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Theme Colors
$theme-colors: (
  "primary": #000120
);

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #339b33;;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Grid
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);